import React from 'react'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faUserLock } from '@fortawesome/free-solid-svg-icons'
import Typist from 'react-typist';
import './App.css'
import crypto from 'crypto-js'
import Web3 from 'web3'
import coverImage from './james-sutton-FqaybX9ZiOU-unsplash.jpg'

let web3
const JSONInterFace =
  [{ "constant": false, "inputs": [{ "internalType": "string", "name": "_md5", "type": "string" }, { "internalType": "string", "name": "_comment", "type": "string" }], "name": "setImage", "outputs": [], "payable": false, "stateMutability": "nonpayable", "type": "function" }, { "constant": true, "inputs": [{ "internalType": "string", "name": "_md5", "type": "string" }], "name": "getImage", "outputs": [{ "internalType": "string", "name": "message", "type": "string" }], "payable": false, "stateMutability": "view", "type": "function" }, { "inputs": [], "payable": false, "stateMutability": "nonpayable", "type": "constructor" }]

function handleDeployImageToChain(web3, account, md5) {
  try {
    const contract = new web3.eth.Contract(JSONInterFace, '0xe108b9e88dA1e0eEfaAb298aD98f5568a3001d5E');
    contract.methods.setImage(md5, 'This image is protect by Image Contract Protector')
      .send({
        from: account
      }).then(data => alert('Success'))
  } catch (err) {
    console.log(err)
  }
}

function handleValidImage(web3, account, md5, setValidMessage) {
  const contract = new web3.eth.Contract(JSONInterFace, '0xe108b9e88dA1e0eEfaAb298aD98f5568a3001d5E');
  contract.methods.getImage(md5)
    .call({
      from: account
    }).then(data => {
      setValidMessage(data)
    })
}

function App() {
  const [MD5, setMD5] = React.useState('*')
  const [imgSrc, setImgSrc] = React.useState('')
  const [account, setAccount] = React.useState('')
  const [validMessage, setValidMessage] = React.useState('')
  const isValid = validMessage === 'You are the image owner.'
  React.useEffect(() => {
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      try {
        window.ethereum.enable().then(async accounts => {
          if (accounts.length > 0) {
            setAccount(accounts[0])
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }, [])
  function onDrop(file) {
    const reader = new FileReader()
    reader.onload = function (e) {
      var contents = e.target.result
      setMD5(crypto.MD5(contents).toString())
    }
    const blobFile = new Blob(file)
    const objectURL = URL.createObjectURL(blobFile);
    setImgSrc(objectURL)
    reader.readAsBinaryString(blobFile)
  }
  if (account === '') {
    return (
      <div className="App">
        <header className="App-header">
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          Please Connect <a href='https://metamask.io/'>MetaMask</a>
        </header>
      </div>
    )
  }
  return (
    <div className="App">
      <header className="App-header">
        <Typist>ContractAddress：0xe108b9e88dA1e0eEfaAb298aD98f5568a3001d5E</Typist>
        <img style={{ position: 'absolute', opacity: 0.4, minWidth: '100%', height: '100%' }} alt="cover image" src={coverImage} />
        {MD5 === '*' && <h1 style={{}}>Choose And Protect Your Image Now <FontAwesomeIcon icon={faUserLock} /></h1>}
        {
          MD5 === '*' ? (
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <div style={{ zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', margin: '0 auto', color: 'black', width: 200, height: 200, background: 'white', borderRadius: 15 }} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FontAwesomeIcon size='5x' icon={faUpload} />
                </div>
              )}
            </Dropzone>
          ) : (
              <React.Fragment>
                <div className='container'>
                  {
                    validMessage !== '' && <div className={`alert ${isValid ? 'alert-success' : 'alert-danger'}`} role="alert">
                      <h4 className="alert-heading">Valid Result</h4>
                      <p>{isValid ? 'Congratulations ! ' : 'Oops ! '}{validMessage}</p>
                      {!isValid && <hr />}
                      {!isValid && <p className="mb-0">Looks bad, do you forget to upload your image ?</p>}
                    </div>
                  }
                  <div style={{ zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='row'>
                    <div className='col-6'>
                      <h3>MD5: {MD5}</h3>
                      <div>
                        <img className='img-thumbnail' alt='' src={imgSrc} />
                      </div>
                    </div>
                    <div style={{ textAlign: 'left' }} className='col-4'>
                      <div>
                        <button onClick={() => handleDeployImageToChain(web3, account, MD5)} type="button" className="btn btn-outline-primary">Protect to chain</button>
                      </div>
                      <div>
                        <button onClick={() => handleValidImage(web3, account, MD5, setValidMessage)} type="button" className="btn btn-outline-info">Valid Image</button>
                      </div>
                      <div>
                        <button onClick={() => {
                          setMD5('*')
                          setValidMessage('')
                        }} type="button" className="btn btn-outline-danger">Remove</button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
        }

      </header>
    </div>
  );
}

export default App;
